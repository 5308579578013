@import '../../../../scss/theme-bootstrap';

.sticky-footer-tout-formatter {
  width: 100%;
  text-align: center;
  border: 1px solid $color-off-black;
  display: flex;
  .sticky-footer-block-formatter & {
    position: fixed;
    background: $white;
    z-index: 1;
    #{$ldirection}: 0;
  }
  & .sticky-footer-tout:first-child {
    border-#{$ldirection}: 0;
  }
}
